(function() {

    var savedQuery = "";
    var $b = $('body');
    var $searchInput = $('.search input[type=text]');

    var timer = null, prev;

    $(document).on('click', '[data-search]', function (event) {
        event.preventDefault();
        $b.removeClass('show-menu');
        $b.addClass('show-search');
        $searchInput.focus();

        var prevHash = "";
        prevHash = window.location.hash;
        var params = getAllUrlParams();
        if (savedQuery != "" && savedQuery != null) {
            params["q"] = savedQuery;
        }
        var paramsString = buildParamString(params);
        var stateObj = { url: window.location.pathname + paramsString + prevHash };
        history.replaceState(stateObj, document.title, window.location.pathname + paramsString + prevHash);

        $('html, body').animate({
            scrollTop: 0
        }, 300);
    });

    function detach() {
        $(document).off('click', '[data-search]');
    }

    $(document).on('click', '[data-search-close]', close);

    function close (event) {
        event.preventDefault();
        var prevHash = "";
        prevHash = window.location.hash;
        var params = getAllUrlParams();
        savedQuery = params["q"];
        delete params["q"];
        var paramsString = buildParamString(params);
        var stateObj = { url: window.location.pathname + paramsString + prevHash };
        history.replaceState(stateObj, document.title, window.location.pathname + paramsString + prevHash);
        $b.removeClass('show-search');
        $('.search input[type=text]').val('')
        $('.search__results .search__holder').html('')
    }

    $(window).keydown(function(event){
        if ($(event.target).closest('#frm-searchForm').length == 0) {
            return
        }

        if( (event.keyCode == 13)) {
            if ( $searchInput.val().length <=2 ) {
                event.preventDefault();
                return false;
            }
        }
        if (event.keyCode == 27) {
            close(event);
        }
    });

    function buildParamString(obj) {
        var search = "";
        if (Object.keys(obj) != 0) {
            search += "?";
        }
        Object.keys(obj).forEach(function (key, index) {
            search += key + "=" + obj[key];

            if (index != Object.keys(obj).length-1) {
                search += "&";
            }
        });
        return search;
    }

    function getAllUrlParams(url) {

        // get query string from url (optional) or window
        var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

        // we'll store the parameters here
        var obj = {};

        // if query string exists
        if (queryString) {

            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];

            // split our query string into its component parts
            var arr = queryString.split('&');

            for (var i=0; i<arr.length; i++) {
                // separate the keys and the values
                var a = arr[i].split('=');

                // in case params look like: list[]=thing1&list[]=thing2
                var paramNum = undefined;
                var paramName = a[0].replace(/\[\d*\]/, function(v) {
                    paramNum = v.slice(1,-1);
                    return '';
                });

                // set parameter value (use 'true' if empty)
                var paramValue = typeof(a[1])==='undefined' ? true : a[1];

                // (optional) keep case consistent
                paramName = paramName.toLowerCase();
                paramValue = paramValue.toLowerCase();

                // if parameter name already exists
                if (obj[paramName]) {
                    // convert value to array (if still string)
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                    }
                    // if no array index number specified...
                    if (typeof paramNum === 'undefined') {
                        // put the value on the end of the array
                        obj[paramName].push(paramValue);
                    }
                    // if array index number specified...
                    else {
                        // put the value at that index number
                        obj[paramName][paramNum] = paramValue;
                    }
                }
                // if param name doesn't exist yet, set it
                else {
                    obj[paramName] = paramValue;
                }
            }
        }

        return obj;
    }

})();

(function() {

    var $searchInput = $('.search input[type=text]'), text;

    $(document).on('focus', '.search input[type=text]', function(event){
        var that = this;
        that.selectionStart = that.selectionEnd = 10000;
    });

    $(document)
        .on('keyup', 'input[data-autosubmit], textarea[data-autosubmit]', function() {
            text = $searchInput.val();
        });


    $.nette.ext('snippet--products-search', {
        success: function (payload) {
            if (payload.snippets) {
                if (payload.searchDone == true) {
                    $searchInput = $('.search input[type=text]');
                    $('html, body').animate({
                        scrollTop: $searchInput.offset().top - 20
                    }, 500);
                    $searchInput.val();
                    $searchInput.focus();
                }
            }
        }
    });

})();